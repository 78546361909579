<template>
  <Page title="전송">
    <br />

    <div class="column-list" style="height: 60vh">
      <div class="column column-2" style="overflow: scroll">
        <h3>{{ subject }}</h3>
        <br />
        <div v-html="body"></div>
      </div>
      <div class="column column-2" style="display: flex; flex-direction: column">
        <input v-model="subject" placeholder="제목" style="width: 100%" />
        <textarea v-model="body" style="width: 100%; flex: 1; margin-top: 1rem"></textarea>
      </div>
    </div>
    <div
      style="
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
      "
    >
      <input type="password" v-model="password" placeholder="password" style="width: 120px" />
      <button @click="send" style="">전송</button>
    </div>
  </Page>
</template>

<script setup>
import { ref } from "vue"
import Page from "../components/Page.vue"
import axios from "axios"

document.title = "뉴스레터 전송"

const subject = ref("[주건희받건희#]")
const body = ref("")
const password = ref("")

const send = async () => {
  if (!confirm("전송하시겠습니까?")) {
    return
  }
  try {
    await axios.post("/api/newsletter", {
      subject: subject.value,
      body: body.value,
      password: password.value,
    })
  } catch (e) {
    if (e.response.status === 401) alert("인증에 실패했습니다.")
    else alert("전송 시작에 실패했습니다.")
    return
  }
  alert("전송이 시작되었습니다.")
}
</script>

<style>
input,
textarea {
  background-color: #1f1f1f;
  color: #ebebeb;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
}
</style>
