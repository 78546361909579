import { createApp } from "vue/dist/vue.esm-bundler"
import App from "./App.vue"
import router from "./router"
import VueGtag from "vue-gtag"
import { createPinia } from "pinia"

var Vue = require("vue")

const pinia = createPinia()

const app = createApp(App)

app.use(router)
app
  .use(VueGtag, {
    config: {
      id: "G-J26JDDTNBK",
      params: { send_page_view: false },
    },
  })
  .mount("#app")
app.use(pinia)

function unix2ISO(t) {
  return new Date((t + 60 * 60 * 9) * 1000).toISOString().replace("T", ". ").replace(/-/gi, ".")
}

function unix2ago(t, lang) {
  const now = Date.now() / 1000
  if (now - t < 1) return lang === "ko" ? "방금" : "now"
  if (now - t < 60) return Math.floor(now - t) + (lang === "ko" ? "초 전" : "s ago")
  if (now - t < 60 * 60) return Math.floor((now - t) / 60) + (lang === "ko" ? "분 전" : "m ago")
  if (now - t < 60 * 60 * 24)
    return Math.floor((now - t) / (60 * 60)) + (lang === "ko" ? "시간 전" : "h ago")
  if (now - t < 60 * 60 * 24 * 7)
    return Math.floor((now - t) / (60 * 60 * 24)) + (lang === "ko" ? "일 전" : "d ago")
  if (now - t < 60 * 60 * 24 * 30)
    return Math.floor((now - t) / (60 * 60 * 24 * 7)) + (lang === "ko" ? "주 전" : "w ago")
  if (now - t < 60 * 60 * 24 * 365)
    return Math.floor((now - t) / (60 * 60 * 24 * 30)) + (lang === "ko" ? "달 전" : "mo ago")
  return Math.floor((now - t) / (60 * 60 * 24 * 365)) + (lang === "ko" ? "년 전" : "y ago")
  // return unix2ISO(p.created).slice(0,11);
}

const ckpts = [
  {
    start: "2005-12-24",
    end: "2005-12-30",
    cityEng: "Los Angeles / Anaheim / Las Vegas",
    cityKr: "로스엔젤레스 / 애너하임 / 라스베이거스",
    country: "🇺🇸",
    id: [
      "ChIJE9on3F3HwoAR9AhGJW_fL-I",
      "ChIJZ-hVgPnW3IARYLErmquJqwE",
      "ChIJ0X31pIK3voARo3mz1ebVzDo",
    ],
    location: [
      { lng: -118.243683, lat: 34.052235 },
      { lng: -117.914299, lat: 33.836594 },
      { lng: -115.139832, lat: 36.169941 },
    ],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2006-07-27",
    end: "2006-08-06",
    cityEng: "Charlottesville / Wachington D.C. / New York",
    cityKr: "샬러츠빌 / 워싱턴 D.C. / 뉴욕",
    country: "🇺🇸",
    id: [
      "ChIJj6RQ6i2Gs4kR_HSLw5bwhpA",
      "ChIJW-T2Wt7Gt4kRKl2I1CJFUsI",
      "ChIJOwg_06VPwokRYv534QaPC8g",
    ],
    location: [
      { lng: -78.4766781, lat: 38.029306 },
      { lng: -77.0365427, lat: 38.8950368 },
      { lng: -74.0059728, lat: 40.7127753 },
    ],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2007-07-27",
    end: "2007-08-02",
    cityEng: "Los Angeles / San Diego / Carlsbad",
    cityKr: "로스엔젤레스 / 샌디에고 / 칼스배드",
    country: "🇺🇸",
    id: [
      "ChIJE9on3F3HwoAR9AhGJW_fL-I",
      "ChIJSx6SrQ9T2YARed8V_f0hOg0",
      "ChIJWe47P0Vz3IARL132_JJVy6Q",
    ],
    location: [
      { lng: -118.243683, lat: 34.052235 },
      { lng: -117.161087, lat: 32.715736 },
      { lng: -117.3505966, lat: 33.1580933 },
    ],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2008-08-01",
    end: "2008-08-09",
    cityEng: "Paris",
    cityKr: "파리",
    country: "🇫🇷",
    id: ["ChIJD7fiBh9u5kcRYJSMaMOCCwQ"],
    location: [{ lng: 2.3514616, lat: 48.8566969 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2009-12-28",
    end: "2009-12-31",
    cityEng: "Bangkok",
    cityKr: "방콕",
    country: "🇹🇭",
    id: ["ChIJ82ENKDJgHTERIEjiXbIAAQE"],
    location: [{ lng: 100.501762, lat: 13.756331 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2010-03-14",
    end: "2010-03-18",
    cityEng: "Siem Reap",
    cityKr: "씨엠립",
    country: "🇰🇭",
    id: ["ChIJeaiRjJoWEDER-rvlPvmqQKk"],
    location: [{ lng: 103.8590321, lat: 13.3617562 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2010-08-12",
    end: "2010-08-19",
    cityEng: "London",
    cityKr: "런던",
    country: "🇬🇧",
    id: ["ChIJdd4hrwug2EcRmSrV3Vo6llI"],
    location: [{ lng: -0.14405508452768728, lat: 51.4893335 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2010-12-09",
    end: "2010-12-13",
    cityEng: "Bangkok",
    cityKr: "방콕",
    country: "🇹🇭",
    id: ["ChIJ82ENKDJgHTERIEjiXbIAAQE"],
    location: [{ lng: 100.501762, lat: 13.756331 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2011-07-27",
    end: "2011-08-07",
    cityEng: "Seattle / Vancouver",
    cityKr: "시애틀 / 밴쿠버",
    country: "🇺🇸 🇨🇦",
    id: ["ChIJs0-pQ_FzhlQRi_OBm-qWkbs"],
    location: [
      { lng: -122.332069, lat: 47.606209 },
      { lng: -123.113953, lat: 49.260872 },
    ],
    purposeEng: "family vacation",
    purposeKr: "지인 방문 및 가족 여행",
  },
  {
    start: "2012-12-31",
    end: "2013-01-05",
    cityEng: "Sydney",
    cityKr: "시드니",
    country: "🇦🇺",
    id: ["ChIJP3Sa8ziYEmsRUKgyFmh9AQM"],
    location: [{ lng: 151.2164539, lat: -33.8548157 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2013-07-31",
    end: "2013-08-03",
    cityEng: "Hawaii",
    cityKr: "하와이",
    country: "🇺🇸",
    id: ["ChIJBeB5Twbb_3sRKIbMdNKCd0s"],
    location: [{ lng: -155.42837009716908, lat: 19.593801499999998 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2013-12-20",
    end: "2013-12-24",
    cityEng: "Phuket",
    cityKr: "푸켓",
    country: "🇹🇭",
    id: ["ChIJT1JixOIxUDARIbo9BoWayuk"],
    location: [{ lng: 98.3918736, lat: 7.887994 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2014-02-15",
    end: "2014-02-19",
    cityEng: "Bali",
    cityKr: "발리",
    country: "🇮🇩",
    id: ["ChIJoQ8Q6NNB0S0RkOYkS7EPkSQ"],
    location: [{ lng: 115.0906401, lat: -8.3304977 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2018-01-11",
    end: "2018-01-21",
    cityEng: "Miami / Orlando",
    cityKr: "마이애미 / 올랜도",
    country: "🇺🇸",
    id: ["ChIJEcHIDqKw2YgRZU-t3XHylv8", "ChIJd7zN_thz54gRnr-lPAaywwo"],
    location: [
      { lng: -80.19362, lat: 25.7741728 },
      { lng: -81.3790304, lat: 28.5421109 },
    ],
    purposeEng: "accompanying dad's overseas training",
    purposeKr: "아빠 해외 훈련 동행",
  },
  {
    start: "2018-08-11",
    end: "2018-08-16",
    cityEng: "Vladivostok / Khabarovsk",
    cityKr: "블라디보스톡 / 하바롭스크",
    country: "🇷🇺",
    id: ["ChIJhdRJUrqcs18RNX6W3dQEZxg", "ChIJyYo2sMbp-l4RUH-6Xw68l_o"],
    location: [
      { lng: 131.8855768, lat: 43.1150678 },
      { lng: 135.076935, lat: 48.481403 },
    ],
    purposeEng: "KAIST 2019 Historical & Cultural Exploration Overseas for Distinguished Freshmen",
    purposeKr: "KAIST 2018 우수새내기 해외역사탐방",
  },
  {
    start: "2018-12-27",
    end: "2018-12-30",
    cityEng: "Ho Chi Minh City",
    cityKr: "호치민",
    country: "🇻🇳",
    id: ["ChIJI9kl2-8udTERFHIryt1Uz0s"],
    location: [{ lng: 106.7017555, lat: 10.7758439 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2019-02-15",
    end: "2019-02-22",
    cityEng: "Sydney",
    cityKr: "시드니",
    country: "🇦🇺",
    id: ["ChIJP3Sa8ziYEmsRUKgyFmh9AQM"],
    location: [{ lng: 151.2164539, lat: -33.8548157 }],
    purposeEng: "trip with my friend",
    purposeKr: "친구와 여행",
  },
  {
    start: "2019-03-27",
    end: "2019-04-02",
    cityEng: "Beijing / Inner Mongolia",
    cityKr: "베이징 / 내몽골자치구",
    country: "🇨🇳",
    id: ["ChIJuSwU55ZS8DURp8_dOLuVd8A", "ChIJ3xRp0kvMBTYRUOnCbYFGl9U"],
    location: [
      { lng: 116.3912757, lat: 39.906217 },
      { lng: 114.3251664, lat: 43.2443242 },
    ],
    purposeEng: "2019 18th Korean-Chinese Green Corps",
    purposeKr: "2019년 18기 한중 녹색봉사단",
  },
  {
    start: "2019-06-14",
    end: "2019-07-08",
    cityEng: "Nanjing / Shanghai / Hangzhou",
    cityKr: "난징 / 상하이 / 항저우",
    country: "🇨🇳",
    id: [
      "ChIJg82NZpuMtTURBhvfeQu2-48",
      "ChIJMzz1sUBwsjURoWTDI5QSlQI",
      "ChIJmaqaQym2SzQROuhNgoPRv6c",
    ],
    location: [
      { lng: 118.7788734, lat: 32.0437711 },
      { lng: 121.4692071, lat: 31.2322758 },
      { lng: 120.2052342, lat: 30.2489634 },
    ],
    purposeEng: "2019 NUAA Summer Program",
    purposeKr: "2019 NUAA Summer Program",
  },
  {
    start: "2019-08-25",
    end: "2019-08-28",
    cityEng: "Taipei",
    cityKr: "타이페이",
    country: "🇹🇼",
    id: ["ChIJi73bYWusQjQRgqQGXK260bw"],
    location: [{ lng: 121.5636796, lat: 25.0375198 }],
    purposeEng: "trip with my friend",
    purposeKr: "친구와 여행",
  },
  {
    start: "2020-02-19",
    end: "2020-02-19",
    cityEng: "Vatican City",
    cityKr: "바티칸 시국",
    country: "🇻🇦",
    id: [
      "ChIJw0rXGxGKJRMRAIE4sppPCQM",
      "ChIJY3a9eKD4KhMRYIDk45AsCAM",
      "ChIJKUgTyxaqfkcREH-QFYcJBwM",
    ],
    location: [{ lng: 12.4528349, lat: 41.9034912 }],
    purposeEng: "trip during a short vacation in student exchange program",
    purposeKr: "교환학기 중간 방학 기간 중 여행",
  },
  {
    start: "2020-02-17",
    end: "2020-02-22",
    cityEng: "Rome / Florence / Venice",
    cityKr: "로마 / 피렌체 / 베니스",
    country: "🇮🇹",
    id: [
      "ChIJw0rXGxGKJRMRAIE4sppPCQM",
      "ChIJY3a9eKD4KhMRYIDk45AsCAM",
      "ChIJKUgTyxaqfkcREH-QFYcJBwM",
    ],
    location: [
      { lng: 12.4829321, lat: 41.8933203 },
      { lng: 11.2555757, lat: 43.7698712 },
      { lng: 12.336084199479028, lat: 45.43699085 },
    ],
    purposeEng: "trip during a short vacation in student exchange program",
    purposeKr: "교환학기 중간 방학 기간 중 여행",
  },
  {
    start: "2020-01-18",
    end: "2020-03-19",
    cityEng: "Toulouse / Albi",
    cityKr: "툴루즈 / 알비",
    country: "🇫🇷",
    id: ["ChIJ_1J17G-7rhIRMBBBL5z2BgQ", "ChIJC7eXebHCrRIR4PA7L5z2BgQ"],
    location: [
      { lng: 1.4442469, lat: 43.6044622 },
      { lng: 2.147899, lat: 43.9277552 },
    ],
    purposeEng: "student exchange program at ISAE-SUPAERO - 2020 spring",
    purposeKr: "ISAE-SUPAERO에서 2020년 봄학기 교환학생 프로그램",
  },
  {
    start: "2022-10-13",
    end: "2022-11-07",
    cityEng: "Montréal / Québec / Toronto / Algonquin / Niagara / New York / Philadelphia",
    cityKr: "몬트리올 / 퀘벡 / 토론토 / 알곤퀸 / 나이아가라 / 뉴욕 / 필라델피아",
    country: "🇨🇦 🇺🇸",
    id: [
      "ChIJDbdkHFQayUwR7-8fITgxTmU",
      "ChIJk4jbBYqWuEwRAzro8GMtxY8",
      "ChIJpTvG15DL1IkRd8S0KlBVNTI",
      "ChIJpSHxyf7d1UwRZXEU9Qnak5o",
      "ChIJt96TyZ5M04kRliMZDZNGf",
      "ChIJOwg_06VPwokRYv534QaPC8g",
      "ChIJ60u11Ni3xokRwVg-jNgU9Yk",
    ],
    location: [
      { lng: -73.5673919, lat: 45.5018869 },
      { lng: -71.2074596, lat: 46.8130816 },
      { lng: -79.3831843, lat: 43.653226 },
      { lng: -78.59676, lat: 45.55397 },
      { lng: -79.0741629, lat: 43.0828162 },
      { lng: -74.0059728, lat: 40.7127753 },
      { lng: -75.1652215, lat: 39.9525839 },
    ],
    purposeEng: "tour",
    purposeKr: "혼자 여행",
  },
  {
    start: "2022-12-21",
    end: "2022-12-26",
    cityEng: "Bangkok",
    cityKr: "방콕",
    country: "🇹🇭",
    id: ["ChIJ82ENKDJgHTERIEjiXbIAAQE"],
    location: [{ lng: 100.501762, lat: 13.756331 }],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2023-12-16",
    end: "2023-12-20",
    cityEng: "Bangkok / Nakhon Nayok",
    cityKr: "방콕 / 나콘 나욕",
    country: "🇹🇭",
    id: ["ChIJ82ENKDJgHTERIEjiXbIAAQE", "ChIJSyjnIC-uHTER6AzOvrA2X10"],
    location: [
      { lng: 100.501762, lat: 13.756331 },
      { lng: 101.0711758, lat: 14.1223885 },
    ],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2023-12-28",
    end: "2024-01-02",
    cityEng: "Melbourne / Peterborough / Port Campbell",
    cityKr: "멜버른 / 피터버러 / 포트 캠벨",
    country: "🇦🇺",
    id: [
      "ChIJ90260rVG1moRkM2MIXVWBAQ",
      "ChIJfRJ4d5SngqoRYMugMKR5BQQ",
      "ChIJP87jaCGggqoR4MugMKR5BQQ",
    ],
    location: [
      { lng: 144.9630576, lat: -37.8136276 },
      { lng: 142.8730034, lat: -38.604338 },
      { lng: 142.9962812, lat: -38.6190434 },
    ],
    purposeEng: "family vacation",
    purposeKr: "가족 여행",
  },
  {
    start: "2024-02-04",
    end: "2024-02-13",
    cityEng:
      "Sydney / Queenstown / Cromwell / Wānaka / Omarama / Pukaki / Lake Tekapo / Fairlie / Christchurch",
    cityKr:
      "시드니 / 퀸스타운 / 크롬웰 / 와나카 / 오마라마 / 푸카키 / 레이크테카포 / 페어리 / 크라이스트처치",
    country: "🇦🇺 🇳🇿",
    id: [
      "ChIJP3Sa8ziYEmsRUKgyFmh9AQM",
      "ChIJX96o1_Ed1akRAKZ5hIbvAAU",
      "ChIJO17SVBQr1akRUGh5hIbvAAU",
      "ChIJby3suR1G1akR4MF5hIbvAAU",
      "ChIJQ-fwdW7SKm0R0Jh5hIbvAAU",
      "ChIJpdZLqB4aK20R8PF5hIbvAAU",
      "ChIJmawxwRqZK20RwIJ5hIbvAAU",
      "ChIJ-3nqWQp7LG0RoGx5hIbvAAU",
      "ChIJAe3FY0gvMm0RRZl5hIbvAAU",
    ],
    location: [
      { lng: 151.2092955, lat: -33.8688197 },
      { lng: 168.6615141, lat: -45.0301511 },
      { lng: 169.1955689, lat: -45.0459401 },
      { lng: 169.1417356, lat: -44.6942992 },
      { lng: 169.9656741, lat: -44.487603 },
      { lng: 170.1126254, lat: -44.2548757 },
      { lng: 170.4793485, lat: -44.0060533 },
      { lng: 170.8288521, lat: -44.0977299 },
      { lng: 172.6305589, lat: -43.5320214 },
    ],
    purposeEng: "trip with my friend",
    purposeKr: "친구와 여행",
  },
]

// Google Maps Place ID 찾기: https://jsfiddle.net/gh/get/library/pure/googlemaps/js-samples/tree/master/dist/samples/places-placeid-finder/jsfiddle
// Google Maps Place ID URL: https://www.google.com/maps/place/?q=place_id:

const otherCkptsArray = [
  ["가평군", "ChIJp3mNK-ooYzURCQHqedpn6CU", { lat: 37.8314, lng: 127.5098 }],
  ["강릉시", "ChIJWw9PleHlYTURRh09nFHGt4A", { lat: 37.7525313, lng: 128.8759523 }],
  ["거제시", "ChIJxxFq8fnMbjURKX-pJeQ7xDw", { lat: 34.8806427, lng: 128.6210824 }],
  ["경주시", "ChIJHQMN-EZOZjURCcjQz-6WFTc", { lat: 35.8556705, lng: 129.2248811 }],
  ["계룡시", "ChIJv24Ip6KtejURbZsXHzwK1Cg", { lat: 36.275523, lng: 127.2472702 }],
  ["고양시", "ChIJCVrQCYOQfDURf-8WPBrXoNA", { lat: 37.6581862, lng: 126.8319452 }],
  ["고흥군", "ChIJ6yfQBmaKbTURa8D3GjQ5hOs", { lat: 34.6044123, lng: 127.276384 }],
  ["공주시", "ChIJb_HHZ1O4ejUR-dGSufE8Bog", { lat: 36.47257, lng: 127.09124 }],
  ["광명시", "ChIJkV2jedRjezURFbmdV2BB19A", { lat: 37.4810539, lng: 126.8631275 }],
  ["광주광역시", "ChIJr6f1ASOJcTURaeoJZDD-G78", { lat: 35.1557358, lng: 126.8354271 }],
  ["광주시", "ChIJzwV2ElGrfDURqk6hmwkLbis", { lat: 37.4002885, lng: 127.29188396709918 }],
  ["기장군", "ChIJYw0RwNiOaDURK1_ri3AValo", { lat: 35.297975, lng: 129.2009199 }],
  ["김포시", "ChIJJY4Cmk6GfDURSOGVqk7LIrY", { lat: 37.615461, lng: 126.7157891 }],
  ["김해시", "ChIJzTOU_m-4aDURsTGGJXbs2Qw", { lat: 35.2310947, lng: 128.8908228 }],
  ["나주시", "ChIJy9IgWRsucjURsc4_ODqPbqE, ", { lat: 35.0158859, lng: 126.7108313 }],
  ["담양군", "ChIJp4u6RPvqcTURFYICFtg_oZs", { lat: 35.320878, lng: 126.9883238 }],
  ["대구광역시", "ChIJ1a3vsrjjZTURMC44oCngkro", { lat: 35.8713, lng: 128.6018 }],
  ["대전광역시", "ChIJAWZKutdIZTURtdOKmJ3WltE", { lat: 36.3496, lng: 127.3848 }],
  ["동해시", "ChIJ4UImuqy4YTURJwC6wLPP2_E", { lat: 37.5067666, lng: 129.0555852 }],
  ["보령시", "ChIJ2wZRFYuZcDURkoS4XQEiKdA", { lat: 36.3331629, lng: 126.6129441 }],
  ["부산광역시", "ChIJNc0j6G3raDURpwhxJHTL2DU", { lat: 35.1799528, lng: 129.0752365 }],
  ["삼척시", "ChIJA_lN9_qmYTURw7zIuWpLCPY", { lat: 37.4498745, lng: 129.1652969 }],
  ["서귀포시", "ChIJd3hJk1FUDDURw5UyWj5tAcY", { lat: 33.2572971, lng: 126.515299 }],
  ["서울특별시", "ChIJzWXFYYuifDUR64Pq5LTtioU", { lat: 37.5666791, lng: 126.9782914 }],
  ["성남시", "ChIJvyAScPGnfDUR1JfjvGZWMVA", { lat: 37.4073695, lng: 127.1162181 }],
  ["세종특별자치시", "ChIJZXXE5qvSejURN175-fU4pk0", { lat: 36.4799999, lng: 127.289 }],
  ["속초시", "ChIJsT1we_S82F8RyD8ltFjA9Ho", { lat: 38.2069574, lng: 128.5913424 }],
  ["수원시", "ChIJEUZ2IApDezURybRd7gIwN_E", { lat: 37.2633325, lng: 127.0287472 }],
  ["순천시", "ChIJLSQoqIcDbjURE5dE2O5aDh0", { lat: 34.9505457, lng: 127.4872992 }],
  ["시흥시", "ChIJDb8DNzNlezURe1yrv81ckKo", { lat: 37.3864796, lng: 126.7841675 }],
  ["안산시", "ChIJp5sdcwcLezUR6RpjPtooIXA", { lat: 37.3218778, lng: 126.8308848 }],
  ["안양시", "ChIJMVA9LMRgezURtTte1ZiXJ3Y", { lat: 37.4027313, lng: 126.9279179 }],
  ["양구군", "ChIJ2f_XlU59YjURfMvtgIiohbU", { lat: 38.1096812, lng: 127.9900596 }],
  ["양양군", "ChIJMTVXtWqq2F8Rk67CIOMTh2o", { lat: 38.0045219, lng: 128.5950959 }],
  ["여수시", "ChIJr6uLHx-UbTURi26I5drZAok", { lat: 34.7546257, lng: 127.659859 }],
  ["연천군", "ChIJiwRU64chfTURJbYgnupP6cM", { lat: 38.0964416, lng: 127.0275846 }],
  ["용인시", "ChIJ9TPOaj1SezUR-7VRGXA1Mp0", { lat: 37.2405741, lng: 127.1785572 }],
  ["울산광역시", "ChIJgd6y4osuZjURiXwuQ-sEK28", { lat: 35.5537228, lng: 129.2380554 }],
  ["의왕시", "ChIJzyD-5cVdezURFT58919_lQ4", { lat: 37.3449082, lng: 126.9689861 }],
  ["인천광역시", "ChIJR4ITliVveTURQmG3LJD9N30", { lat: 37.456, lng: 126.7052 }],
  ["전주시", "ChIJY9gWA0MjcDURmkLDkeAXvMc", { lat: 35.8242715, lng: 127.1480657 }],
  ["제주시", "ChIJ_XltioXgDDUR4pyR4WmImks", { lat: 33.4930566, lng: 126.5130589 }],
  ["천안시", "ChIJ5a7fvhUoezUR1KkLpiWn_fo", { lat: 36.804138, lng: 127.2025586 }],
  ["청주시", "ChIJfa9k5K0nZTURiYhqfJx4mfw", { lat: 36.6272962, lng: 127.498731 }],
  ["춘천시", "ChIJq_vvHybkYjURBDWI49gjy3U", { lat: 37.8810569, lng: 127.7297641 }],
  ["태백시", "ChIJS52lMVFzYTURfg4cr5CZLzU", { lat: 37.1640654, lng: 128.9855649 }],
  ["태안군", "ChIJZxmSSfASejURQE9m6F6Ov9w", { lat: 36.745422, lng: 126.298363 }],
  ["통영시", "ChIJJanWom-5bjURWfcztnVQVuI", { lat: 34.854254, lng: 128.433205 }],
  ["파주시", "ChIJuQhD6D7sfDURB6J0Dx5TGW8", { lat: 37.7598955, lng: 126.7801502 }],
  ["평창군", "ChIJ1wXyh-gtYjUR-Zdo4gjrijg", { lat: 37.556735, lng: 128.4826261 }],
  ["평택시", "ChIJhUYLdeIXezURSetXeqzUMyY", { lat: 36.9924966, lng: 127.1127168 }],
  ["포항시", "ChIJM8KTwLr9ZjURftSE6Hw24sg", { lat: 36.0929227, lng: 129.3052666 }],
  ["하남시", "ChIJ4bL8p4qxfDUROAPBhxrxWBo", { lat: 37.53025785, lng: 127.21164735272373 }],
  ["해남군", "ChIJ9Q-AJQYAczURNIm3Ff5RO7U", { lat: 34.55269, lng: 126.52431 }],
  ["화성시", "ChIJIfZd0f8SezURp-W-4kU9FD0", { lat: 37.1616306, lng: 126.8654604 }],
  ["화순군", "ChIJ8y-C0DX1cTURz0JJDzwyd5M", { lat: 35.0643095, lng: 126.9864311 }],
  // ["", "", { lat: , lng:  }],
  ["괌", "ChIJ7yQPk_92H2cRpuWzxZGucVU", { lat: 13.444137999999999, lng: 144.7336264457675 }],
  ["바탐", "ChIJBc5UwOi82TERsMsgsoCdAwM", { lat: 1.1300779, lng: 104.0529207 }],
  ["싱가포르", "ChIJdZOLiiMR2jERxPWrUs9peIg", { lat: 1.357107, lng: 103.8194992 }],
  // ["", "", { lat: , lng:  }],
]

const otherCkpts = otherCkptsArray.map((pt) => pt[2])

function isMobile() {
  return window.innerWidth <= 1000
}

export { Vue, unix2ISO, unix2ago, ckpts, otherCkpts, isMobile }
